import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { StyledMenuItem } from "../../common/FormInputs";
import {
  polarToCartesian,
  getColorForKey,
  getCaseLabelByStatus,
} from "./function";
import StagesPie from "./StagesPie";
import { Circle, HyperLink, Tooltip } from "../styles";
import CustomDrawer from "./CustomDrawer";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  svg: {
    width: "200px",
    height: "200px",
  },
  legend: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
  },
  colorBox: {
    marginLeft: "12px",
    width: "20px",
    height: "20px",
    borderRadius: "8px",
    marginRight: theme.spacing(1),
  },
}));

const PieChart = ({
  modal,
  setModal,
  filterRef,
  filterData,
  currentData,
  pieObject,
}) => {
  const classes = useStyles();
  const [trigger, setTrigger] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });
  // Process data

  const data = {
    Initiated: [],
    InProgress: [],
    Completed: [],
    "Resolution Closed": [],
  };

  const filterPieDate = useMemo(() => {
    let outputObject = {};

    Object.keys(pieObject).forEach((group) => {
      outputObject[group] = [];
    });

    if (currentData?.length) {
      currentData.forEach((item) => {
        const value = item.detailedStage;

        // Find the key for the given status
        let keyFound = false;
        Object.keys(pieObject).forEach((group) => {
          if (
            pieObject[group].includes(value) &&
            (filterRef.current.lotID
              ? filterRef.current.lotID === item.lotID
              : true)
          ) {
            keyFound = true;
            outputObject[group].push(value);
          }
        });

        // If the status is not found in any existing key, ignore it
        if (!keyFound) {
          // Optionally, handle statuses that do not match any key
        }
      });
    }
    setTrigger(false);
    return outputObject;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  let cumulativePercent = 0;

  let storedData = Object.keys(filterPieDate ? filterPieDate : data).map(
    (key) => ({
      label: key,
      value: filterPieDate[key].length,
      color: getColorForKey(key),
    }),
  );

  const dataWithPercentage = storedData.map((item) => ({
    ...item,
    percentage:
      (item.value / storedData.reduce((sum, item) => sum + item.value, 0)) *
      100, // Calculate percentage
  }));

  const generatePieSlices = () => {
    return dataWithPercentage.map((slice) => {
      const [startX, startY] = polarToCartesian(cumulativePercent);
      cumulativePercent += slice.percentage / 100;
      const [endX, endY] = polarToCartesian(cumulativePercent);

      const largeArcFlag = slice.percentage > 50 ? 1 : 0;

      return (
        <svg width="200px" height="200px" viewBox="0 0 35 35">
          <Path
            style={{ cursor: "pointer" }}
            key={slice.label}
            d={`M16,16 L${startX},${startY} A16,16 0 ${largeArcFlag} 1 ${endX},${endY} Z`}
            fill={slice.color}
            // stroke="white"
            // strokeWidth="0.2"
            onMouseOver={(event) =>
              handleMouseOver(
                `${slice.label}: ${slice.percentage.toFixed(1)}%`,
                event,
              )
            }
            onMouseOut={handleMouseOut}
          />
        </svg>
      );
    });
  };

  const nonEmptyArrays = Object.values(
    filterPieDate ? filterPieDate : data,
  ).filter((arr) => arr.length > 0);

  const handleMouseOver = (content, event) => {
    // Get the mouse coordinates relative to the SVG
    const svg = event.currentTarget.ownerSVGElement;
    const { left, top } = svg.getBoundingClientRect();
    const x = event.clientX - left;
    const y = event.clientY - top;

    setTooltip({
      visible: true,
      content,
      x,
      y,
    });
  };

  const handleMouseOut = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  /**
   * @description substages
   */

  const findSubStages = useMemo(
    (label) => {
      if (filterRef.current.caseStage?.length) {
        const filterKeys = Object.entries(filterPieDate).filter(
          ([key]) => key === filterRef.current.caseStage,
        );

        const frequencies = filterKeys[0][1]?.reduce((acc, value) => {
          acc[value] = (acc[value] || 0) + 1;
          return acc;
        }, {});

        const totalCount = filterKeys[0][1]?.length;

        const sub_stages = Object.keys(frequencies).map((key) => ({
          label: key,
          value: frequencies[key],
          detailedStage: key,
          percentage: (frequencies[key] / totalCount) * 100, // Calculate percentage
        }));

        return sub_stages.map((item) => ({
          ...item,
          label: getCaseLabelByStatus(item),
          color: getColorForKey(getCaseLabelByStatus(item)),
        }));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterRef.current.caseStage],
  );

  const handleClick = (label) => {
    if (filterRef?.current?.caseStage !== label) {
      filterRef.current = {
        ...filterRef.current,
        caseStage: label,
        detailedStage: "",
      };
      filterData(label, filterRef.current, "caseStage");
    }
  };

  return (
    <CustomDrawer state={modal?.state} setState={setModal} label="Drawer">
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>
            Pie Chart{" "}
            <LabelValueContainer>
              <Label>Total :</Label>
              <Value>{currentData?.length}</Value>
            </LabelValueContainer>
          </Heading>
          <HyperLink
            onClick={() => {
              filterRef.current = {
                fromDate: "",
                toDate: "",
                fromCaseId: "",
                toCaseId: "",
                lotNumber: "",
                partyId: [],
                caseStage: "",
                lotID: "",
                detailedStage: "",
              };
              filterData("test", filterRef);
              setTrigger(true);
            }}
          >
            Remove all filters
          </HyperLink>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Box className={classes.chartContainer}>
            <svg viewBox="0 0 200 200" className={classes.svg}>
              {nonEmptyArrays.length === 1
                ? dataWithPercentage
                    .filter((item) => item.value)
                    .map((arr, index) => (
                      <Circle
                        key={index}
                        cx={90}
                        cy={90}
                        r={90}
                        fill={arr.color}
                        onMouseOver={(event) =>
                          handleMouseOver(
                            `${arr.label}: ${arr.percentage.toFixed(1)}%`,
                            event,
                          )
                        }
                        onMouseOut={handleMouseOut}
                      />
                    ))
                : generatePieSlices()}
            </svg>
            {tooltip.visible && <Tooltip>{tooltip.content}</Tooltip>}
          </Box>
          <Box className={classes.legend}>
            {dataWithPercentage.map((slice, index) => (
              <Box
                key={index}
                className={classes.legendItem}
                style={{ marginTop: "10px" }}
              >
                <Box
                  className={classes.colorBox}
                  style={{ backgroundColor: slice.color }}
                />
                <StyledMenuItem
                  // onClick={() => setPieLable(slice.label)}
                  onClick={() => slice.value !== 0 && handleClick(slice.label)}
                >
                  {slice.label} ({slice.value})
                </StyledMenuItem>
              </Box>
            ))}
          </Box>
          <StagesPie
            {...{ findSubStages, cumulativePercent, filterRef, filterData }}
          />
        </AgentFormContainer>
      </DrawerContainer>
    </CustomDrawer>
  );
};

export default PieChart;

const DrawerContainer = styled.div`
  width: 100vw;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  align-items: center;
  justify-content: center;
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Path = styled.path`
  cursor: pointer;
`;

const LabelValueContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

const Label = styled.span`
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 16px;
  color: ${COLORS.INPUT_LABEL};
`;

const Value = styled.span`
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
  margin-left: 5px;
`;
