import React, { Fragment, useEffect, useState } from "react";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import { AssigninBulkCaseId } from "../../../helpers/validationSchema";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import { Modal } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CaesLifeCycleStatus from "./CaseLifeCycleStatus";
import useUser from "../../../hooks/useUser";

const inputs = [
  {
    type: "number",
    name: "startCaseId",
    label: "From CaseId",
    required: true,
    autoFocus: true,
  },
  {
    type: "number",
    name: "endCaseId",
    label: "End CaseId",
    required: true,
  },
  {
    type: "text",
    name: "exceptCaseIds",
    label: "Except CaseIds",
    required: false,
  },
];

const Index = ({ lifecycleModal, setLifeCycleModal }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [lifeCycle, setLifeCycle] = useState(false);
  const [status, setStatus] = useState("");
  const [exceptCaseIds, seTExceptCaseIds] = useState("");
  const { userObject } = useUser();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payload = {
        startCaseId: values?.startCaseId,
        endCaseId: values?.endCaseId,
        exceptCaseIds: values?.exceptCaseIds,
        status: values.status.value,
        currentUser: userObject?.name,
      };
      const response = await CaseService.NeutralBulkCase(payload);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setLifeCycleModal({ state: false });
    }
  }

  useEffect(() => {
    if (lifecycleModal) {
      setStatus("");
      setStart();
      setEnd();
      seTExceptCaseIds();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifecycleModal]);

  return (
    <Fragment>
      <Modal
        open={lifecycleModal?.state}
        onClose={() => setLifeCycleModal({ state: false })}
      >
        <DrawerContainer>
          <Container>
            <HeadingContainer>
              <Heading>{"Case Status"}</Heading>
              <CloseModal
                onClick={() => setLifeCycleModal({ state: false })}
                src={require("../../../assets/images/closeModal.svg")}
              />
            </HeadingContainer>
            <FormContainer className="disabledArrows">
              <Formik
                initialValues={{
                  startCaseId: start || "",
                  endCaseId: end || "",
                  status: status || "",
                  exceptCaseIds: exceptCaseIds || "",
                }}
                onSubmit={onFormSubmit}
                validationSchema={AssigninBulkCaseId}
                validateOnBlur
                enableReinitialize
                validateOnChange
              >
                {({
                  values,
                  handleChange,
                  errors,
                  handleSubmit,
                  touched,
                  handleBlur,
                  setFieldValue,
                  isValid,
                }) => (
                  <StyledForm onFormSubmit={handleSubmit}>
                    {inputs?.map((input, key) => (
                      <FormField
                        white
                        {...{
                          key,
                          input,
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                        }}
                      />
                    ))}
                    <div style={{ marginBottom: "10px" }} />
                    <CaseManagerCard>
                      <div
                        className="add_circle"
                        onClick={() => {
                          setLifeCycle(true);
                          setStart(values?.startCaseId);
                          setEnd(values?.endCaseId);
                          seTExceptCaseIds(values?.exceptCaseIds);
                        }}
                      >
                        <AddIcon className="add_icon" />
                      </div>
                      <Column>
                        <Flex className="flex-1">
                          {status?.label ? (
                            <CaseManagerName>{status?.label}</CaseManagerName>
                          ) : (
                            <div className="add_lifecycle_name">
                              Update Case Status
                            </div>
                          )}
                        </Flex>
                      </Column>
                    </CaseManagerCard>
                    <ButtonContainer>
                      <PrimaryCTAButton
                        type="submit"
                        disabled={
                          !isValid ||
                          !values?.startCaseId ||
                          !values?.endCaseId ||
                          !values?.status
                        }
                        onClick={handleSubmit}
                        style={{ width: "45%", margin: "auto" }}
                      >
                        Update
                      </PrimaryCTAButton>
                      <PrimaryOutlinedCTAButton
                        type="submit"
                        onClick={() => setLifeCycleModal({ state: false })}
                        style={{ width: "45%", margin: "auto" }}
                      >
                        Cancel
                      </PrimaryOutlinedCTAButton>
                    </ButtonContainer>
                  </StyledForm>
                )}
              </Formik>
            </FormContainer>
          </Container>
        </DrawerContainer>
      </Modal>
      <CaesLifeCycleStatus
        {...{ lifeCycle, setLifeCycle, status, setStatus }}
      />
    </Fragment>
  );
};

export default Index;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  max-width: 516px;
  min-height: 318px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 470px;
  }
  & .status_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .status_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .status-list {
    overflow: auto;
    height: 265px;
    border: 4px solid transparent;
    border-radius: 8px;
    margin-left: 10px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormContainer = styled.div`
  padding: 15px;
  height: 450px;
`;

export const CaseManagerCard = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
  & .add_lifecycle_name {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme?.fonts?.primaryFontSemiBold};
  }
  & .add_icon {
    color: ${COLORS.BTN_GREEN};
    font-size: 18px;
  }
`;

export const Column = styled.div`
  display: flex;
  margin-left: 10px;
  height: 100%;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const CaseManagerName = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  padding: 0 6px;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
