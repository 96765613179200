import React from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import moment from "moment";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import { MultipleHearingsSchema } from "../../../helpers/validationSchema";

export default function MultipleHearings({ openHearing, setOpenHearing }) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: `Uploading NextHearing Date` });
      const date = moment(new Date(values.startDate)).format("YYYY-MM-DD");
      const time = moment(new Date(values?.startTime)).format("HH:mm:ss");
      const selectedTime = moment(`${date} ${time}`);
      const postData = {
        nextHearingDate: {
          date: values.startDate
            ? moment(selectedTime).format("DD/MM/YYYY HH:mm:ss")
            : null,
          time: values?.startTime
            ? moment(selectedTime).format("DD/MM/YYYY HH:mm:ss")
            : null,
          hearingType: values?.hearingType,
          remarks: values?.remarks,
        },
        startCaseId: values?.startCaseId,
        endCaseId: values?.endCaseId,
      };
      const res = await CaseService.bulkNextHearingDate(postData);
      if (res) {
        enqueueSnackbar("Hearing Date Updated Successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpenHearing(false);
    }
  }

  const inputs = [
    {
      name: "hearingType",
      label: "Hearing Type",
      type: "text",
      required: true,
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "text",
      required: true,
    },
  ];

  return (
    <Modal
      open={openHearing}
      onClose={() => setOpenHearing(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Scheduled Hearing</Heading>
            <CloseModal
              onClick={() => setOpenHearing(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <Formik
              initialValues={{
                startCaseId: "",
                endCaseId: "",
                startDate: null,
                startTime: null,
                hearingType: "",
                remarks: "",
              }}
              validationSchema={MultipleHearingsSchema}
              onSubmit={onFormSubmit}
              validateOnBlur
              validateOnChange
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                handleBlur,
                setFieldValue,
              }) => (
                <StyledForm onSubmit={handleSubmit} className="mis-dashboard">
                  <div className="date-time">
                    <div className="date">
                      <FormField
                        white
                        input={{
                          type: "number",
                          name: "startCaseId",
                          label: "From CaseId",
                          required: true,
                        }}
                        {...{
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                        }}
                      />
                    </div>
                    <div className="time">
                      <FormField
                        white
                        input={{
                          type: "number",
                          name: "endCaseId",
                          label: "End CaseId",
                          required: true,
                        }}
                        {...{
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                        }}
                      />
                    </div>
                  </div>
                  <div className="date-time">
                    <div className="date">
                      <FormField
                        input={{
                          name: "startDate",
                          label: "Date",
                          type: "date",
                          // required: true
                        }}
                        placeholderText="N/A"
                        minDate={new Date()}
                        {...{
                          touched,
                          errors,
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                        }}
                      />
                    </div>
                    <div className="time">
                      {values.startDate ? (
                        <FormField
                          input={{
                            name: "startTime",
                            label: "Time (IST)",
                            type: "time",
                            required: true,
                          }}
                          placeholderText="N/A"
                          {...{
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      ) : (
                        <FormField
                          input={{
                            name: "startTime",
                            label: "Time (IST)",
                            type: "time",
                            // required: true
                          }}
                          placeholderText="N/A"
                          {...{
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="reason">
                    {inputs?.map((input, index) => (
                      <FormField
                        key={index}
                        {...{
                          input,
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                        }}
                      />
                    ))}
                  </div>
                  <ButtonContainer>
                    <PrimaryOutlinedCTAButton
                      style={{ width: "45%", height: "38px" }}
                      onClick={() => setOpenHearing(false)}
                    >
                      Cancel
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{ width: "45%", height: "38px" }}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </PrimaryCTAButton>
                  </ButtonContainer>
                </StyledForm>
              )}
            </Formik>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 450px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormContainer = styled.div`
  padding: 15px;
  .link-container {
    margin: 12px;
  }
  .date-time {
    display: flex;
    .date {
      margin-left: 12px;
      margin-right: 12px;
      width: 100%;
    }
    .time {
      margin-left: 12px;
      margin-right: 12px;
      width: 100%;
    }
  }
  .reason {
    margin-top: 5px;
    margin-left: 12px;
    margin-right: 12px;
  }
`;
